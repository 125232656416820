/**
 * @param num
 * @returns {string|null}
 * Return a full Tailwind CSS class rather than using string concatenation
 * to ensure the classes are not purged in build
 */

export function getLgLayoutColumns(num) {
  if (!num) {
    return null
  }
  num = parseInt(num)

  if (num === 4) {
    return "lg:w-4/23"
  } else if (num === 5) {
    return "lg:w-5/23"
  } else if (num === 6) {
    return "lg:w-6/23"
  } else if (num === 7) {
    return "lg:w-7/23"
  } else if (num === 8) {
    return "lg:w-8/23"
  } else if (num === 9) {
    return "lg:w-9/23"
  } else if (num === 10) {
    return "lg:w-10/23"
  } else if (num === 11) {
    return "lg:w-11/23"
  } else if (num === 12) {
    return "lg:w-12/23"
  } else if (num === 13) {
    return "lg:w-13/23"
  } else if (num === 14) {
    return "lg:w-14/23"
  } else if (num === 15) {
    return "lg:w-15/23"
  } else if (num === 16) {
    return "lg:w-16/23"
  } else if (num === 17) {
    return "lg:w-17/23"
  } else if (num === 18) {
    return "lg:w-18/23"
  } else if (num === 19) {
    return "lg:w-19/23"
  } else if (num === 20) {
    return "lg:w-20/23"
  } else if (num === 21) {
    return "lg:w-21/23"
  } else if (num === 22) {
    return "lg:w-22/23"
  }
}

export function getXlLayoutColumns(num) {
  if (!num) {
    return null
  }
  num = parseInt(num)

  if (num === 4) {
    return "xl:w-4/23"
  } else if (num === 5) {
    return "xl:w-5/23"
  } else if (num === 6) {
    return "xl:w-6/23"
  } else if (num === 7) {
    return "xl:w-7/23"
  } else if (num === 8) {
    return "xl:w-8/23"
  } else if (num === 9) {
    return "xl:w-9/23"
  } else if (num === 10) {
    return "xl:w-10/23"
  } else if (num === 11) {
    return "xl:w-11/23"
  } else if (num === 12) {
    return "xl:w-12/23"
  } else if (num === 13) {
    return "xl:w-13/23"
  } else if (num === 14) {
    return "xl:w-14/23"
  } else if (num === 15) {
    return "xl:w-15/23"
  } else if (num === 16) {
    return "xl:w-16/23"
  } else if (num === 17) {
    return "xl:w-17/23"
  } else if (num === 18) {
    return "xl:w-18/23"
  } else if (num === 19) {
    return "xl:w-19/23"
  } else if (num === 20) {
    return "xl:w-20/23"
  } else if (num === 21) {
    return "xl:w-21/23"
  } else if (num === 22) {
    return "xl:w-22/23"
  }
}

export function getLgLayoutRows(num) {
  if (!num) {
    return null
  }
  num = parseInt(num)

  if (num === 3) {
    return "lg:h-row-3"
  } else if (num === 4) {
    return "lg:h-row-4"
  } else if (num === 5) {
    return "lg:h-row-5"
  } else if (num === 6) {
    return "lg:h-row-6"
  } else if (num === 7) {
    return "lg:h-row-7"
  } else if (num === 8) {
    return "lg:h-row-8"
  } else if (num === 9) {
    return "lg:h-row-9"
  } else if (num === 10) {
    return "lg:h-row-10"
  }
}

export function getXlLayoutRows(num) {
  if (!num) {
    return null
  }
  num = parseInt(num)

  if (num === 3) {
    return "xl:h-row-3"
  } else if (num === 4) {
    return "xl:h-row-4"
  } else if (num === 5) {
    return "xl:h-row-5"
  } else if (num === 6) {
    return "xl:h-row-6"
  } else if (num === 7) {
    return "xl:h-row-7"
  } else if (num === 8) {
    return "xl:h-row-8"
  } else if (num === 9) {
    return "xl:h-row-9"
  } else if (num === 10) {
    return "xl:h-row-10"
  }
}

export function getLgNegativeMargin(num) {
  if (!num) {
    return null
  }
  num = parseInt(num)

  if (num === 0) {
    return "lg:mt-0"
  } else if (num === 1) {
    return "lg:-mt-row"
  } else if (num === 2) {
    return "lg:-mt-row-2"
  } else if (num === 3) {
    return "lg:-mt-row-3"
  } else if (num === 4) {
    return "lg:-mt-row-4"
  } else if (num === 5) {
    return "lg:-mt-row-5"
  }
}

export function getXlNegativeMargin(num) {
  if (!num) {
    return null
  }
  num = parseInt(num)

  if (num === 0) {
    return "xl:mt-0"
  } else if (num === 1) {
    return "xl:-mt-row"
  } else if (num === 2) {
    return "xl:-mt-row-2"
  } else if (num === 3) {
    return "xl:-mt-row-3"
  } else if (num === 4) {
    return "xl:-mt-row-4"
  } else if (num === 5) {
    return "xl:-mt-row-5"
  }
}

import * as React from "react"
import PropTypes from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image"

import getAssetFormat from "../utils/getAssetFormat"
import {
  getXlLayoutColumns,
  getXlLayoutRows,
  getXlNegativeMargin,
} from "../utils/getGridSizeClasses"

const ProjectSliceImage = ({ slice, index }) => {
  if (!slice) return null
  const {
    image,
    columns__xl_layout_,
    rows__xl_layout_,
    negative_margin_top__xl_layout_,
  } = slice.primary

  // Calculate image format based on image ratio
  const format = getAssetFormat(image.dimensions)

  // Calculate grid height/width classes and sizes attribute based on image format
  const userWidthClasses = getXlLayoutColumns(columns__xl_layout_)
  const userHeightClasses = getXlLayoutRows(rows__xl_layout_)
  const marginClasses = getXlNegativeMargin(negative_margin_top__xl_layout_)
  const widthOuterClasses = `w-full ${userWidthClasses}`
  let widthInnerClasses =
    "w-10/12 sm:w-9/12 sm:mr-1/12 lg:w-10/12 lg:mr-1/12 xl:w-full xl:mr-0"
  let sizeSm = "83.333333333333333vw"
  let sizeMd = "75vw"
  if (format === "landscape") {
    widthInnerClasses = "w-full sm:w-10/12 lg:w-11/12 xl:w-full"
    sizeSm = "100vw"
    sizeMd = "83.333333333333333vw"
  } else if (format === "portrait") {
    widthInnerClasses =
      "w-10/12 sm:w-8/12 sm:mr-2/12 lg:mr-3/12 xl:w-full xl:mr-0"
    sizeSm = "83.333333333333333vw"
    sizeMd = "66.666666666666667vw"
  }
  let heightClasses = `h-row-10 sm:h-row-11 ${userHeightClasses}`
  if (format === "landscape") {
    heightClasses = `h-row-8 sm:h-row-9 ${userHeightClasses}`
  } else if (format === "portrait") {
    heightClasses = `h-row-12 sm:h-row-13 ${userHeightClasses}`
  }
  const sizes = `(min-width: 640px) ${sizeMd}, (min-width: 1280px) ${
    (columns__xl_layout_ / 24) * 100
  }vw, ${sizeSm}`

  // Set eager loading for first images
  const loading = index < 3 ? "eager" : "lazy"

  return (
    <div
      className={`flex justify-center xl:mr-1/23 mb-row ${widthOuterClasses} ${marginClasses}`}
    >
      <div
        className={`overflow-hidden ${widthInnerClasses} ${heightClasses} bg-black bg-opacity-10`}
      >
        <GatsbyImage
          image={image.gatsbyImageData}
          alt={image.alt ? image.alt : ""}
          className={`object-cover h-full w-full`}
          loading={loading}
          sizes={sizes}
        />
      </div>
    </div>
  )
}

ProjectSliceImage.propTypes = {
  slice: PropTypes.object,
  index: PropTypes.number,
}

export default ProjectSliceImage

import * as React from "react"
import PropTypes from "prop-types"
import { RichText } from "prismic-reactjs"
import htmlSerializer from "../utils/htmlSerializer"

import TextContent from "./TextContent"
import GridRowAutoHeight from "./GridRowAutoHeight"
import {
  getXlLayoutColumns,
  getXlLayoutRows,
  getXlNegativeMargin,
} from "../utils/getGridSizeClasses"
import * as styles from "./ProjectSliceQuote.module.css"

const ProjectSliceQuote = ({ slice }) => {
  if (!slice) return null
  const {
    quote,
    credit,
    columns__xl_layout_,
    rows__xl_layout_,
    negative_margin_top__xl_layout_,
  } = slice.primary

  // Check text fields are not empty
  const isQuote = quote.raw.length > 0
  const isCredit = credit.raw.length > 0
  if (!isQuote && !isCredit) return null

  // Assign column and row classes
  const widthClasses = getXlLayoutColumns(columns__xl_layout_)
  const heightClasses = getXlLayoutRows(rows__xl_layout_)
  const marginClasses = getXlNegativeMargin(negative_margin_top__xl_layout_)

  return (
    <div
      className={`w-full flex justify-center xl:mr-1/23 mb-row ${widthClasses} ${heightClasses} ${marginClasses}`}
    >
      <div className="w-10/12 sm:w-8/12 sm:mr-2/12 lg:mr-3/12 xl:w-full xl:mr-0">
        <GridRowAutoHeight>
          <figure>
            {isQuote && (
              <blockquote
                className={`text-xl leading-tight sm:text-2xl sm:leading-tight ${styles.quote}`}
              >
                <TextContent>
                  <RichText
                    render={quote.raw}
                    htmlSerializer={htmlSerializer}
                  />
                </TextContent>
              </blockquote>
            )}
            {isCredit > 0 && (
              <figcaption
                className={`text-sm leading-snug ${isQuote ? "mt-2" : ""}`}
              >
                <TextContent>
                  <RichText
                    render={credit.raw}
                    htmlSerializer={htmlSerializer}
                  />
                </TextContent>
              </figcaption>
            )}
          </figure>
        </GridRowAutoHeight>
      </div>
    </div>
  )
}

ProjectSliceQuote.propTypes = {
  slice: PropTypes.object,
}

export default ProjectSliceQuote

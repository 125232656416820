import * as React from "react"
import PropTypes from "prop-types"
import { RichText } from "prismic-reactjs"
import htmlSerializer from "../utils/htmlSerializer"

import TextContent from "./TextContent"
import GridRowAutoHeight from "./GridRowAutoHeight"
import {
  getXlLayoutColumns,
  getXlLayoutRows,
  getXlNegativeMargin,
} from "../utils/getGridSizeClasses"

const ProjectSliceText = ({ slice }) => {
  if (!slice) return null
  const {
    text,
    columns__xl_layout_,
    rows__xl_layout_,
    negative_margin_top__xl_layout_,
  } = slice.primary

  // Check text field is not empty
  if (text.raw.length === 0) return null

  // Assign column and row classes
  const widthClasses = getXlLayoutColumns(columns__xl_layout_)
  const heightClasses = getXlLayoutRows(rows__xl_layout_)
  const marginClasses = getXlNegativeMargin(negative_margin_top__xl_layout_)

  return (
    <div
      className={`w-full flex justify-center xl:mr-1/23 mb-row ${widthClasses} ${heightClasses} ${marginClasses}`}
    >
      <div className="w-10/12 sm:w-8/12 sm:mr-2/12 lg:mr-3/12 xl:w-full xl:mr-0 text-base leading-snug">
        <GridRowAutoHeight>
          <TextContent>
            <RichText render={text.raw} htmlSerializer={htmlSerializer} />
          </TextContent>
        </GridRowAutoHeight>
      </div>
    </div>
  )
}

ProjectSliceText.propTypes = {
  slice: PropTypes.object,
}

export default ProjectSliceText

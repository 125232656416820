import * as React from "react"
import PropTypes from "prop-types"

import ProjectSliceImage from "./ProjectSliceImage"
import ProjectSliceVideo from "./ProjectSliceVideo"
import ProjectSliceText from "./ProjectSliceText"
import ProjectSliceQuote from "./ProjectSliceQuote"

const ProjectSliceZone = ({ slices }) => {
  const sliceComponents = {
    image: ProjectSliceImage,
    video: ProjectSliceVideo,
    text: ProjectSliceText,
    quote: ProjectSliceQuote,
  }

  return (
    <div className="w-full xl:pl-1/24 flex flex-wrap">
      {slices.map((slice, index) => {
        const SliceComponent = sliceComponents[slice.slice_type]
        if (SliceComponent) {
          return (
            <SliceComponent
              slice={slice}
              index={index}
              key={`slice-${index}`}
            />
          )
        }
        return null
      })}
    </div>
  )
}

ProjectSliceZone.propTypes = {
  slices: PropTypes.array,
}

export default ProjectSliceZone

import * as React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { linkResolver } from "../utils/linkResolver"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import { createGlobalStyle } from "styled-components"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Grid from "../components/Grid"
import PageTitle from "../components/PageTitle"
import ProjectSliceZone from "../components/ProjectSliceZone"
import hexToRGB from "../utils/hexToRGB"
import useProjectsPageData from "../hooks/useProjectsPageData"
import ProjectPagination from "../components/ProjectPagination"

// Get the index of the current project by matching the uid against a list of
// all projects
const getProjectIndex = (projects, currentProject) => {
  for (let i = 0; i < projects.length; i++) {
    const { project } = projects[i]
    if (project.uid === currentProject.uid) {
      return i
    }
  }
  return null
}

// Get the previous project in a list of projects
const getPrevProject = (projectIndex, projects) => {
  // Check for a projectIndex
  if (projectIndex === undefined || projectIndex === null) return null

  // If index is 0 then return last project
  if (projectIndex === 0) {
    return projects[projects.length - 1]
  }
  // Else return previous project
  return projects[projectIndex - 1]
}

// Get the next project in a list of projects
const getNextProject = (projectIndex, projects) => {
  // Check for a projectIndex
  if (projectIndex === undefined || projectIndex === null) return null

  // If index is last then return first project
  if (projectIndex === projects.length - 1) {
    return projects[0]
  }
  // Else return next project
  return projects[projectIndex + 1]
}

const ProjectPage = ({ data }) => {
  if (!data) return null
  const { prismicProject } = data
  const {
    title,
    introduction,
    featured_image,
    horizontal_line_color,
    horizontal_line_opacity,
    vertical_line_color,
    vertical_line_opacity,
    body,
  } = prismicProject.data

  const grid = {
    horizontal: "b",
    vertical: "a",
  }

  // Get next and previous projects by checking the order defined in the
  // projects page data
  const { projects } = useProjectsPageData()
  // Get index of this project by matching uid
  const projectIndex = getProjectIndex(projects, prismicProject)
  // Then get the next and previous projects by index
  const prevProject = getPrevProject(projectIndex, projects)
  const nextProject = getNextProject(projectIndex, projects)

  // Set custom grid line colors
  let GridColor = null
  if (
    horizontal_line_color &&
    horizontal_line_opacity &&
    vertical_line_color &&
    vertical_line_opacity
  ) {
    GridColor = createGlobalStyle`
    body {
      --color-horizontal: rgba(${hexToRGB(
        horizontal_line_color
      )}, ${horizontal_line_opacity});
      --color-vertical: rgba(${hexToRGB(
        vertical_line_color
      )}, ${vertical_line_opacity});
    }
  `
  }

  // Prepare SEO metadata
  const metaTitle = title.text ? title.text : null
  const metaDescription =
    introduction.raw && introduction.raw[0] ? introduction.raw[0].text : null
  const metaImage = featured_image.url ? featured_image.url : null

  return (
    <Layout>
      <SEO title={metaTitle} description={metaDescription} image={metaImage} />
      {GridColor !== null && <GridColor />}
      <Grid horizontal={grid.horizontal} vertical={grid.vertical}>
        <div className="pt-row xl:pt-0 pb-row min-h-50vh">
          <PageTitle title={title} showBreadcrumb={true} />
          <div className="xl:-mt-row">
            <ProjectSliceZone slices={body} />
          </div>
        </div>
      </Grid>
      {prevProject && nextProject && (
        <ProjectPagination prev={prevProject} next={nextProject} />
      )}
    </Layout>
  )
}

ProjectPage.propTypes = {
  data: PropTypes.object,
}

export const query = graphql`
  query ProjectPage($id: String) {
    prismicProject(id: { eq: $id }) {
      _previewable
      uid
      data {
        title {
          text
        }
        introduction {
          raw
        }
        featured_image {
          url
        }
        horizontal_line_color
        horizontal_line_opacity
        vertical_line_color
        vertical_line_opacity
        body {
          ... on PrismicProjectDataBodyImage {
            slice_type
            primary {
              image {
                alt
                gatsbyImageData
                dimensions {
                  width
                  height
                }
              }
              columns__xl_layout_
              rows__xl_layout_
              negative_margin_top__xl_layout_
            }
          }
          ... on PrismicProjectDataBodyVideo {
            slice_type
            primary {
              video {
                embed_url
                provider_name
                width
                height
              }
              columns__xl_layout_
              rows__xl_layout_
              negative_margin_top__xl_layout_
            }
          }
          ... on PrismicProjectDataBodyText {
            slice_type
            primary {
              text {
                raw
              }
              columns__xl_layout_
              rows__xl_layout_
              negative_margin_top__xl_layout_
            }
          }
          ... on PrismicProjectDataBodyQuote {
            slice_type
            primary {
              quote {
                raw
              }
              credit {
                raw
              }
              columns__xl_layout_
              rows__xl_layout_
              negative_margin_top__xl_layout_
            }
          }
        }
      }
    }
  }
`

export default withPrismicPreview(ProjectPage, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
  },
])

import * as React from "react"
import PropTypes from "prop-types"

import Grid from "../components/Grid"
import ProjectPaginationLink from "./ProjectPaginationLink"

const ProjectPagination = ({ prev, next }) => {
  const { project: prevProject } = prev
  const { project: nextProject } = next

  const grid = {
    horizontal: "b",
    vertical: "b",
  }

  return (
    <Grid horizontal={grid.horizontal} vertical={grid.vertical}>
      <div className="flex justify-center pt-row">
        <div className="w-10/12 lg:w-44/48 flex items-start">
          <ProjectPaginationLink type="prev" project={prevProject} />
          <ProjectPaginationLink type="next" project={nextProject} />
        </div>
      </div>
    </Grid>
  )
}

ProjectPagination.propTypes = {
  prev: PropTypes.object,
  next: PropTypes.object,
}

export default ProjectPagination

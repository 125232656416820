import * as React from "react"
import PropTypes from "prop-types"
import "plyr/dist/plyr.css"

import getAssetFormat from "../utils/getAssetFormat"
import * as styles from "./ProjectSliceVideo.module.css"
import {
  getXlLayoutColumns,
  getXlLayoutRows,
  getXlNegativeMargin,
} from "../utils/getGridSizeClasses"

const ProjectSliceVideo = ({ slice }) => {
  if (!slice) return null
  const {
    video,
    columns__xl_layout_,
    rows__xl_layout_,
    negative_margin_top__xl_layout_,
  } = slice.primary

  // Calculate video format based on image ratio
  const format = getAssetFormat({ width: video.width, height: video.height })

  // Calculate grid height and width classes based on image format
  const userWidthClasses = getXlLayoutColumns(columns__xl_layout_)
  const userHeightClasses = getXlLayoutRows(rows__xl_layout_)
  const marginClasses = getXlNegativeMargin(negative_margin_top__xl_layout_)
  const widthOuterClasses = `w-full ${userWidthClasses}`
  let widthInnerClasses = "w-10/12 sm:w-9/12 sm:mr-1/12 xl:w-full xl:mr-0"
  if (format === "landscape") {
    widthInnerClasses = "w-full sm:w-10/12 xl:w-full"
  } else if (format === "portrait") {
    widthInnerClasses = "w-10/12 sm:w-8/12 sm:mr-2/12 xl:w-full xl:mr-0"
  }
  let heightClasses = `h-row-10 sm:h-row-11 ${userHeightClasses}`
  if (format === "landscape") {
    heightClasses = `h-row-5 sm:h-row-7 ${userHeightClasses}`
  } else if (format === "portrait") {
    heightClasses = `h-row-12 sm:h-row-13 ${userHeightClasses}`
  }

  // Define media queries
  let videoOptions
  if (typeof window !== "undefined") {
    const sm = window.matchMedia("(min-width: 640px")
    const xl = window.matchMedia("(min-width: 1280px")

    // Get video columns and rows
    const checkCols = () => {
      if (xl.matches) {
        return columns__xl_layout_
      } else if (sm.matches) {
        if (format === "landscape") {
          return 10
        } else if (format === "portrait") {
          return 8
        } else {
          return 9
        }
      } else {
        if (format === "landscape") {
          return 12
        } else if (format === "portrait") {
          return 10
        } else {
          return 10
        }
      }
    }

    let cols = checkCols()

    const checkRows = () => {
      if (xl.matches) {
        return rows__xl_layout_
      } else if (sm.matches) {
        if (format === "landscape") {
          return 7
        } else if (format === "portrait") {
          return 13
        } else {
          return 11
        }
      } else {
        if (format === "landscape") {
          return 5
        } else if (format === "portrait") {
          return 12
        } else {
          return 10
        }
      }
    }

    let rows = checkRows()

    // Set video ratio based on user settings and current gridRatio
    const checkGridRatio = () => {
      if (xl.matches) {
        return 1.5
      } else if (sm.matches) {
        return 0.75
      } else {
        return 1.125
      }
    }

    let gridRatio = checkGridRatio()

    // Define video options object
    videoOptions = {
      ratio: `${cols}:${rows * gridRatio}`,
      controls: [
        "play-large",
        "play",
        "progress",
        "current-time",
        "mute",
        "volume",
        "fullscreen",
      ],
    }
  }

  // Get video player element
  const playerWrapper = React.useRef()

  // Conditionally import Plyr package when not SSR (importing normally causes
  // build errors)
  // TODO figure out a way to update the video ratio on window resize
  React.useLayoutEffect(() => {
    if (typeof window !== "undefined" && typeof document !== "undefined") {
      const Plyr = require("plyr")
      const player = new Plyr(playerWrapper.current, videoOptions)

      // Destroy player on component unmount
      return () => {
        player.destroy()
      }
    }
  }, [])

  return (
    <div
      className={`flex justify-center xl:mr-1/23 mb-row ${widthOuterClasses} ${marginClasses} ${styles.customPlyr}`}
    >
      <div className={`overflow-hidden ${widthInnerClasses} ${heightClasses}`}>
        <div
          ref={playerWrapper}
          data-plyr-provider={video.provider_name.toLowerCase()}
          data-plyr-embed-id={video.embed_url}
        ></div>
      </div>
    </div>
  )
}

ProjectSliceVideo.propTypes = {
  slice: PropTypes.object,
}

export default ProjectSliceVideo

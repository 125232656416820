import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import * as styles from "./ProjectsPageLink.module.css"
import getAssetFormat from "../utils/getAssetFormat"
import GridRowAutoHeight from "./GridRowAutoHeight"
import formatTypography from "../utils/formatTypography"

const ProjectPaginationLink = ({ type, project }) => {
  if (!project.document) {
    return null
  }
  // Destructure project object
  const { featured_image: image, title, introduction } = project.document.data
  // Assign prev/next label
  const label = type === "prev" ? "Previous project" : "Next project"
  // Calculate image format based on image ratio
  const format = getAssetFormat(image.dimensions)
  // Calculate grid height and width classes based on image format
  let heightClasses = "h-row-4 sm:h-row-5 lg:h-row-3"
  if (format === "landscape") {
    heightClasses = "h-row-3 sm:h-row-4 lg:h-row-2"
  } else if (format === "portrait") {
    heightClasses = "h-row-5 sm:h-row-7 lg:h-row-4"
  }
  // Define sizes attribute for img tags
  const sizes = "(min-width: 1024px) 18.75vw, 33.333333333333333vw"

  return (
    <Link
      to={project.url}
      className={`block w-5/10 lg:w-22/44 flex flex-wrap items-start ${styles.link}`}
    >
      <div
        className={`w-4/5 lg:w-9/22 lg:mr-1/22 ${heightClasses} overflow-hidden bg-black bg-opacity-10`}
      >
        <GatsbyImage
          image={image.gatsbyImageData}
          alt={image.alt ? image.alt : ""}
          className={`object-cover h-full w-full ${styles.linkImage}`}
          sizes={sizes}
        />
      </div>
      <div className="w-4/5 lg:w-10/22 xl:w-7/22 text-xs leading-snug lg:text-sm lg:leading-snug">
        <GridRowAutoHeight>
          <p className="font-bold lg:mb-leading-snug pt-1 lg:pt-0">{label}</p>
          <h2 className={styles.linkText}>{formatTypography(title.text)}</h2>
          <p className="hidden lg:block">
            {formatTypography(introduction.text)}
          </p>
        </GridRowAutoHeight>
      </div>
    </Link>
  )
}

ProjectPaginationLink.propTypes = {
  type: PropTypes.string,
  project: PropTypes.object,
}

export default ProjectPaginationLink
